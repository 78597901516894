// to be used at Career Others and Career List
/* eslint-disable prettier/prettier */
const CAREERS_API = '/.rest/api/v2/careers?';

export interface Career {
    id: number;
    jobId: string;
    feed: string;
    title: string;
    language: string;
    locationTitle: string;
    department: string;
    jobType: string;
    city: string;
    region: string;
    country: string;
    channel: string;
    highlights: string[];
    description: string;
    url: string;
    applicationLink: string;
    startDate: number;
    endDate: number;
    lastUpdated: number;
    version: number;
    // custom properties
    company: string;
    jobCategory: string;
    customProperties: {
        postingDetailUrl: string;
        countryIsoCode: string;
    }
    // created properties
    detailsUrl: string;
    detailsUrlTarget: string;
}
interface CareersFiltersResponse {
    data: Career[];
    filters: Record<string, Array<string>>;
    totalResults: number;
}

export default class Careers {
    static abortController = new AbortController();

    private static apiBaseUrl(): string {
        // eslint-disable-next-line dot-notation
        return window['contextPath'] + CAREERS_API;
    }

    // Note: This function assumes that the jobs-overview page has the same URL structure across all sites.
    private static detailsUrlGenerator(career: Career, siteLangs: Array<string>): { url: string; target: string } {
        const currentUrl = window.location.pathname;
        const segments = currentUrl.split('/').filter(Boolean);
        if (!isNaN(Number(segments[segments.length - 1]))) {
            segments.pop();
        }
        const isAuthor = (segments[0] === 'author');
        const currentLang = isAuthor ? segments[2] : segments[1];
        const currentSite = isAuthor ? segments[1] : segments[0];
        const countryIsoCode = career.customProperties.countryIsoCode.split(':')[0].trim().toLowerCase();
        const langCode = career.language.split('_')[0].trim().toLowerCase();
        let target = '_self';

        // verify if same site/country and have language
        if (currentSite === countryIsoCode && siteLangs && siteLangs.includes(langCode)) {
            isAuthor ? segments[2] = langCode : segments[1] = langCode;
        // diferent site/country
        } else if (currentSite !== countryIsoCode){
            isAuthor ? segments[2] = langCode : segments[1] = langCode;
            isAuthor ? segments[1] = countryIsoCode : segments[0] = countryIsoCode;
            target = '_blank';
        }
        const url = '/' + segments.join('/') + '/' + encodeURIComponent(career.jobId);
        return { url, target };
    }

    // get Careers - user for careerOthers
    public static async loadCareers(sorting: string, limit: number, searchCriteria: Record<string, unknown>, siteLangs: Array<string>, excludeId: string): Promise<Array<Career>> {

        const url = this.apiBaseUrl() +
                    'locale=' + 'all' +
                    '&sortBy=' + sorting +
                    '&limit=' + (limit + 1) +
                    '&searchCriteria=' + encodeURIComponent(JSON.stringify(searchCriteria));
        try {
            const response = await fetch(url);
            if (response.status === 200) {
                const data = await response.json();
                const updatedData = data.data.map((item: Career) => {
                    const { url, target } = this.detailsUrlGenerator(item, siteLangs);
                    return {
                        ...item,
                        // Add detail URL and target for career items
                        detailsUrl: url,
                        detailsUrlTarget: target,
                    };
                })
                .filter((item: Career) => item.jobId !== excludeId)
                .slice(0, limit);
                return updatedData;
            }
            return [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }


    // get Careers + Filters - user for careerList
    public static async loadMoreCareers(first: boolean, sorting: string, limit: number, offset: number, search: string, searchCriteria: string, countries: Array<string>, siteLangs: Array<string>): Promise<CareersFiltersResponse> {
        this.abortController.abort();
        this.abortController = new AbortController();

        const url = this.apiBaseUrl() +
                    'locale=' + 'all' +
                    countries.map(c => '&country=' + c).join('') +
                    '&sortBy=' + sorting +
                    '&limit=' + limit +
                    '&offset=' + offset +
                    '&keyword=' + encodeURI(search.trim()) +
                    '&searchCriteria=' + encodeURIComponent(JSON.stringify(searchCriteria));
        try {
            const response = first ? await fetch(url) : await fetch(url, { signal: this.abortController.signal });
            if (response.status === 200) {
                const data = await response.json();
                const updatedData = data.data.map((item: Career) => {
                    const { url, target } = this.detailsUrlGenerator(item, siteLangs);
                    return {
                        ...item,
                        // Add detail URL and target for career items
                        detailsUrl: url,
                        detailsUrlTarget: target,
                    };
                });
                
                return { ...data, data: updatedData };                
            }
            return { data: [], filters: {}, totalResults: 0 } as CareersFiltersResponse;
        } catch (e) {
            if (e.name !== 'AbortError') {
                console.error(e);
                return { data: [], filters: {}, totalResults: 0 } as CareersFiltersResponse;
            }
        }
    }
}
